import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { cpf as cpfValid } from 'cpf-cnpj-validator'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'

import api from '../services/api'

import { Input } from './Form/Input'
import { Checkbox } from './Form/Checkbox'

import { LandingPageProcessDTO } from '../dtos/LandingPageProcessDTO'

const contactUsFormSchema = z.object({
  name: z
    .string()
    .min(10, { message: 'O campo nome completo é obrigatório.' })
    .toUpperCase(),
  cpf: z.string().refine(
    (value) => {
      // Remove caracteres não numéricos do CPF
      const cleanedCpf = value.replace(/\D/g, '')

      if (cleanedCpf.length === 0) {
        return true
      }

      // Verifica se o CPF é válido utilizando o cpf-cnpj-validator
      return cpfValid.isValid(cleanedCpf)
    },
    {
      message: 'CPF inválido',
    },
  ),
  birthDate: z.string(),
  cellPhone: z.string().min(14, { message: 'O campo celular é obrigatório.' }),
  email: z
    .string()
    .email({ message: 'O campo e-mail é obrigatório.' })
    .toLowerCase(),
})

type ContactUsFormData = z.infer<typeof contactUsFormSchema>

interface FormContactUsProps {
  landingPageProcess: LandingPageProcessDTO
}

export function FormContactUs({ landingPageProcess }: FormContactUsProps) {
  const [checkedItems, setCheckedItems] = useState([true, true, true])
  const [toastOptions, setToastOptions] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<ContactUsFormData>({
    resolver: zodResolver(contactUsFormSchema),
  })

  const toast = useToast()
  const navigate = useNavigate()

  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  async function handleContactUs(data: ContactUsFormData) {
    try {
      await api.post('contacts', {
        landingPageId: Number(landingPageProcess.id),
        name: data.name,
        cpf: data.cpf.replace(/\D/g, ''),
        email: data.email,
        cellPhone: data.cellPhone.replace(/\D/g, ''),
        birthDate: data.birthDate,
        acceptCommunicationEmail: checkedItems[0],
        acceptCommunicationSms: checkedItems[1],
        acceptCommunicationWhatsapp: checkedItems[2],
      })

      setValue('name', '')
      setValue('cpf', '')
      setValue('email', '')
      setValue('cellPhone', '')
      setValue('birthDate', '')

      navigate('agradecimento')
    } catch (err) {
      toast({
        title: 'Error ao efetuar o cadastro!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      gap="8"
      p="4"
      my={['8', '12']}
      id="contact"
    >
      <Flex
        as="form"
        onSubmit={handleSubmit(handleContactUs)}
        maxW={1100}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="8"
        px="14"
        borderRadius={20}
        bgColor="gray.100"
        shadow="xl"
      >
        <Text color={landingPageProcess.colorBase} textAlign="center" my="6">
          Deixe o seu cadastro para receber mais informações e tirar suas
          dúvidas
        </Text>

        <Flex flexDirection="column" width="100%" gap="4">
          <Input
            label="(*) Nome Completo:"
            type="text"
            placeholder="Digite seu nome completo"
            {...register('name')}
            colorLabel={landingPageProcess.colorBase}
            error={errors.name}
          />

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              label="(*) E-mail:"
              type="email"
              placeholder="Digite seu e-mail"
              {...register('email')}
              colorLabel={landingPageProcess.colorBase}
              error={errors.email}
            />

            <Input
              as={InputMask}
              mask="(99)99999-9999"
              label="(*) Celular:"
              type="text"
              placeholder="Digite seu celular"
              {...register('cellPhone')}
              colorLabel={landingPageProcess.colorBase}
              error={errors.cellPhone}
            />
          </Flex>

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              as={InputMask}
              mask="999.999.999-99"
              label="CPF:"
              type="text"
              placeholder="Digite seu CPF"
              {...register('cpf')}
              colorLabel={landingPageProcess.colorBase}
              error={errors.cpf}
            />

            <Input
              label="Data de nascimento:"
              type="date"
              placeholder="Digite sua data de nascimento"
              {...register('birthDate')}
              colorLabel={landingPageProcess.colorBase}
              error={errors.birthDate}
            />
          </Flex>

          <Text w="100%" color="red" textAlign="left">
            (*) campos obrigatório
          </Text>

          <Box>
            <Flex align="center" mt={1} mb={0}>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                label="Eu concordo em receber comunicações:"
                colorLabel={landingPageProcess.colorBase}
                name="allAccept"
                onChange={(e) =>
                  setCheckedItems([
                    e.target.checked,
                    e.target.checked,
                    e.target.checked,
                  ])
                }
              />

              <Button
                color={landingPageProcess.colorBase}
                _hover={{
                  bg: 'transparent',
                  color: landingPageProcess.colorHeader,
                }}
                onClick={() => setToastOptions(!toastOptions)}
                size="md"
              >
                ver opções
              </Button>
            </Flex>

            <Stack pl={6} spacing={2} display={toastOptions ? 'flex' : 'none'}>
              <Checkbox
                isChecked={checkedItems[0]}
                label="E-mail marketing."
                colorLabel={landingPageProcess.colorBase}
                name="acceptEmail"
                onChange={(e) =>
                  setCheckedItems([
                    e.target.checked,
                    checkedItems[1],
                    checkedItems[2],
                  ])
                }
              />
              <Checkbox
                isChecked={checkedItems[1]}
                label="SMS."
                colorLabel={landingPageProcess.colorBase}
                name="acceptSms"
                onChange={(e) =>
                  setCheckedItems([
                    checkedItems[0],
                    e.target.checked,
                    checkedItems[2],
                  ])
                }
              />
              <Checkbox
                isChecked={checkedItems[2]}
                label="WhatsApp."
                colorLabel={landingPageProcess.colorBase}
                name="acceptWhatsapp"
                onChange={(e) =>
                  setCheckedItems([
                    checkedItems[0],
                    checkedItems[1],
                    e.target.checked,
                  ])
                }
              />
            </Stack>
          </Box>

          {landingPageProcess.urlInstitutional !== '' && (
            <Text>
              Ao informar meus dados, eu concordo com a {` `}
              <Link
                href={landingPageProcess.urlPrivacy}
                color={landingPageProcess.colorBase}
                fontWeight="bold"
                target="_blank"
              >
                Política de Privacidade.
              </Link>
            </Text>
          )}
        </Flex>

        <Flex
          as={Button}
          type="submit"
          isLoading={isSubmitting}
          colorScheme="teal"
          variant="outline"
          spinnerPlacement="start"
          w="100%"
          maxW={250}
          h={50}
          bgColor={landingPageProcess.colorBase}
          color="#FFF"
          border="2px solid transparent"
          _hover={{
            borderColor: landingPageProcess.colorBase,
            bgColor: 'transparent',
            color: landingPageProcess.colorBase,
          }}
          fontWeight="bold"
          fontSize="20"
          borderRadius="12"
          justify="center"
          align="center"
          gap="2"
          my="8"
        >
          Cadastrar
        </Flex>
      </Flex>
    </Flex>
  )
}
