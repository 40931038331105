import { ReactNode, createContext, useEffect, useState } from 'react'

import getReturnValuesCountDown from '../utils/getReturnValuesCountDown'

interface CountDown {
  days: number
  hours: number
  minutes: number
  seconds: number
}

interface CountDownContextData {
  countDown: CountDown
  startCountDownDate(targetDate: number): void
}

interface CountDownProviderProps {
  children: ReactNode
}

export const CountDownContext = createContext<CountDownContextData>(
  {} as CountDownContextData,
)

function CountDownProvider({ children }: CountDownProviderProps) {
  const [countDownDate, setCountDownDate] = useState(0)
  const [countDownValue, setCountDownValue] = useState(
    countDownDate - new Date().getTime(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDownValue(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return (
    <CountDownContext.Provider
      value={{
        countDown: getReturnValuesCountDown(countDownValue),
        startCountDownDate: setCountDownDate,
      }}
    >
      {children}
    </CountDownContext.Provider>
  )
}

export { CountDownProvider }
