import { Flex, Skeleton, SkeletonText, Stack, Text } from '@chakra-ui/react'
import { SocialButton } from './SocialButton'
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
} from '@phosphor-icons/react'
import { useLandingPageProcess } from '../hook/useLandingPageProcess'

export function Footer() {
  const { landingPageProcess } = useLandingPageProcess()

  if (!landingPageProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          mt={['8', '14']}
          bgColor="#cecece"
        >
          <Flex
            maxW={1250}
            w="100%"
            justify="space-between"
            align="center"
            p="4"
            gap="4"
            flexDirection={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            <SkeletonText noOfLines={1} spacing="4" skeletonHeight="2" />

            <Stack spacing="4" direction="row">
              <Skeleton height="50px" width="50px" />

              <Skeleton height="50px" width="50px" />
            </Stack>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      as="footer"
      w="100%"
      justify="center"
      align="center"
      mt={['8', '14']}
      bgColor={landingPageProcess.colorHeader}
    >
      <Flex
        maxW={1250}
        w="100%"
        justify="space-between"
        align="center"
        p="4"
        gap="4"
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
        <Text
          color={
            landingPageProcess.colorHeader === '#FFF'
              ? landingPageProcess.colorBase
              : '#FFF'
          }
          textAlign={['center', 'left']}
        >
          Copyright © 2024 Podemos. Todos os direitos reservados.
        </Text>

        <Stack spacing="4" direction="row">
          {landingPageProcess.urlInstagram && (
            <SocialButton
              href={landingPageProcess.urlInstagram}
              icon={InstagramLogo}
              title="Instagram"
              color={
                landingPageProcess.colorHeader === '#FFF'
                  ? landingPageProcess.colorBase
                  : '#FFF'
              }
              bgColor={landingPageProcess.colorHeader}
            />
          )}

          {landingPageProcess.urlFacebook && (
            <SocialButton
              href={landingPageProcess.urlFacebook}
              icon={FacebookLogo}
              title="Instagram"
              color={
                landingPageProcess.colorHeader === '#FFF'
                  ? landingPageProcess.colorBase
                  : '#FFF'
              }
              bgColor={landingPageProcess.colorHeader}
            />
          )}

          {landingPageProcess.urlLinkedin && (
            <SocialButton
              href={landingPageProcess.urlLinkedin}
              icon={LinkedinLogo}
              title="Instagram"
              color={
                landingPageProcess.colorHeader === '#FFF'
                  ? landingPageProcess.colorBase
                  : '#FFF'
              }
              bgColor={landingPageProcess.colorHeader}
            />
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}
