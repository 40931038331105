/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, createContext, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../services/api'

import { LandingPageProcessDTO } from '../dtos/LandingPageProcessDTO'

interface RequestLandingPageProcess {
  processAlias: string
}

interface LandingPageProcessContextData {
  landingPageProcess: LandingPageProcessDTO
  seachLandingPageProcess({
    processAlias,
  }: RequestLandingPageProcess): Promise<void>
}

interface LandingPageProcessProviderProps {
  children: ReactNode
}

export const LandingPageProcessContext =
  createContext<LandingPageProcessContextData>(
    {} as LandingPageProcessContextData,
  )

function LandingPageProcessProvider({
  children,
}: LandingPageProcessProviderProps) {
  const [landingPageProcess, setLandingPageProcess] =
    useState<LandingPageProcessDTO>({} as LandingPageProcessDTO)

  const navigate = useNavigate()

  const seachLandingPageProcess = useCallback(
    async ({ processAlias }: RequestLandingPageProcess) => {
      try {
        const { data } = await api.get<LandingPageProcessDTO>(
          `landing-page-process/alias/${processAlias}`,
        )

        setLandingPageProcess(data)
      } catch (err) {
        navigate('/')
      }
    },
    [],
  )

  return (
    <LandingPageProcessContext.Provider
      value={{
        landingPageProcess,
        seachLandingPageProcess,
      }}
    >
      {children}
    </LandingPageProcessContext.Provider>
  )
}

export { LandingPageProcessProvider }
