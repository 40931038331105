import { ReactNode } from 'react'

import { LandingPageProcessProvider } from './LandingPageProcessContext'
import { CountDownProvider } from './CountDownContext'

interface AppProviderProps {
  children: ReactNode
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <LandingPageProcessProvider>
      <CountDownProvider>{children}</CountDownProvider>
    </LandingPageProcessProvider>
  )
}

export default AppProvider
