import { useEffect } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react'

import { useLandingPageProcess } from '../../hook/useLandingPageProcess'

import { Footer } from '../../components/Footer'

const Routes = () => {
  const { processAlias } = useParams()
  const { landingPageProcess, seachLandingPageProcess } =
    useLandingPageProcess()

  useEffect(() => {
    seachLandingPageProcess({ processAlias: String(processAlias) })
  }, [seachLandingPageProcess, processAlias])

  useEffect(() => {
    document.title = `${landingPageProcess?.processName}`
  }, [landingPageProcess?.processName])

  if (!landingPageProcess.id) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          h={[280, 280, 120]}
          bgColor="#FFF"
        >
          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            align="center"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            px="6"
          >
            <Skeleton height="80px" width={200} />

            <Flex gap="4" flexDirection={['column', 'column', 'row']}>
              <Skeleton height="55px" width={200} />

              <Skeleton height="55px" width={200} />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" w="100%" align="center">
          <Skeleton height="400px" width="100%" />

          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            p="4"
            my={['8', '12']}
          >
            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              border="2px solid #002147"
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>

            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              border="2px solid #002147"
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
}

export default Routes
