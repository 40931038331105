import { useEffect } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'

import { ImageError404 } from '../components/ImageError404'

const Error404 = () => {
  useEffect(() => {
    document.title = 'Podemos'
  }, [])

  return (
    <Flex align="center" justify="center" height="100vh" width="100%">
      <Flex w="100%" maxW={1280} align="center" justify="center" gap="6">
        <Flex flexDirection="column" w="100%" maxW={500} p="4">
          <Heading size="2xl" color="#0097FD">
            Ops, esta página não foi encontrada
          </Heading>
          <Text mt={4}>
            Parece que você se perdeu... Tente voltar para a página anterior ou
            acessar a home.
          </Text>
        </Flex>
        <Box w="100%" maxW={500}>
          <ImageError404 />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Error404
