import { useContext } from 'react'

import { LandingPageProcessContext } from '../context/LandingPageProcessContext'

export function useLandingPageProcess() {
  const context = useContext(LandingPageProcessContext)

  if (!context) {
    throw new Error(
      'useLandingPageProcess must be used within a LandingPageProcessProvider',
    )
  }

  return context
}
