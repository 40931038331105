/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import dayjs from 'dayjs'
import {
  Flex,
  Image,
  Link as ChakraLink,
  Heading,
  Text,
  Skeleton,
  SkeletonText,
  useToast,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'
import { ArrowRight, FileImage, FilePdf, FileText } from '@phosphor-icons/react'

import api from '../services/api'
import { useLandingPageProcess } from '../hook/useLandingPageProcess'
import dateIsSameOrBefore from '../utils/dateIsSameOrBefore'
import dateIsSameOrAfter from '../utils/dateIsSameOrAfter'
import getMimeTypeFromExtension from '../utils/getMimeTypeFromExtension'

import { WhatsAppButton } from '../components/WhatsAppButton'
import { FormContactUs } from '../components/FormContactUs'

interface ResponseTimeline {
  id: number
  landingPageId: string
  description: string
  dateStart: string
  dateEnd: string
  subscriptionOpen: string
  url: string
}

interface ResponseDocument {
  id: number
  landingPageId: string
  description: string
  url: string
}

export function Home() {
  const [timelines, setTimelines] = useState<ResponseTimeline[]>([])
  const [infoRegistration, setInfoRegistration] = useState<ResponseTimeline>()
  const [documents, setDocuments] = useState<ResponseDocument[]>([])

  const { landingPageProcess } = useLandingPageProcess()

  const toast = useToast()

  async function fetchListTimeline() {
    try {
      const { data } = await api.get<ResponseTimeline[]>(
        `/landing-page-process/timeline/${landingPageProcess.id}`,
      )

      setTimelines(data)

      const indexTimeline = data.findIndex(
        (timeline) =>
          timeline.description === 'Inscrições' ||
          timeline.description === 'Inscrição',
      )
      const info = data[indexTimeline]

      setInfoRegistration(info)
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar lista a timeline',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  async function fetchListDocuments() {
    try {
      const { data } = await api.get<ResponseDocument[]>(
        `/landing-page-process/documents/all/${landingPageProcess.id}`,
      )

      setDocuments(data)
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar lista os documentos',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    fetchListTimeline()
    fetchListDocuments()
  }, [])

  if (!landingPageProcess.id) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          h={[280, 280, 120]}
          bgColor="#FFF"
        >
          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            align="center"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            px="6"
          >
            <Skeleton height="80px" width={200} />

            <Flex gap="4" flexDirection={['column', 'column', 'row']}>
              <Skeleton height="55px" width={200} />

              <Skeleton height="55px" width={200} />
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" w="100%" align="center">
          <Skeleton height="400px" width="100%" />

          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            p="4"
            my={['8', '12']}
          >
            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>

            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex
        as="header"
        w="100%"
        justify="center"
        align="center"
        position="fixed"
        zIndex="1000"
        h={[280, 280, 100]}
        bgColor={landingPageProcess.colorHeader}
      >
        <Flex
          maxW={1280}
          w="100%"
          justify="space-between"
          align="center"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          px="6"
        >
          <Image
            src={landingPageProcess.urlLogo.split('^')[0]}
            alt="Logo"
            maxW={180}
            w="100%"
          />

          <Flex gap="4" flexDirection={['column', 'column', 'row']}>
            {infoRegistration &&
              dateIsSameOrAfter(infoRegistration.dateStart) &&
              dateIsSameOrBefore(infoRegistration.dateEnd) && (
                <Button
                  as={Link}
                  to="contact"
                  borderRadius="12"
                  fontWeight="bold"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  cursor="pointer"
                  color={landingPageProcess.colorHeader}
                  bgColor={
                    landingPageProcess.colorHeader !== '#FFFFFF'
                      ? '#FFFFFF'
                      : landingPageProcess.colorBase
                  }
                  border="2px solid transparent"
                  _hover={{
                    textDecoration: 'none',
                    color:
                      landingPageProcess.colorHeader !== '#FFFFFF'
                        ? '#FFFFFF'
                        : landingPageProcess.colorBase,
                    borderColor:
                      landingPageProcess.colorHeader !== '#FFFFFF'
                        ? '#FFFFFF'
                        : landingPageProcess.colorBase,
                    bgColor: 'transparent',
                  }}
                >
                  Quero me inscrever
                </Button>
              )}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        as="main"
        flexDirection="column"
        w="100%"
        pt={[280, 280, 100]}
        align="center"
      >
        {landingPageProcess.whatsappNumber && (
          <WhatsAppButton
            href={`http://wa.me/${landingPageProcess.whatsappNumber}/?text=Ola`}
          />
        )}

        <Flex w="100%" justify="center">
          <Image
            src={landingPageProcess.urlBanner.split('^')[0]}
            alt=""
            maxW={1920}
            w="100%"
          />
        </Flex>

        <Flex
          maxW={1280}
          w="100%"
          justify="space-between"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          p="4"
          my={['8', '12']}
        >
          <Flex
            w="100%"
            maxW={600}
            justifyContent="space-between"
            flexDirection="column"
            gap="4"
            borderRadius={12}
            border={`2px solid ${landingPageProcess.colorBase}`}
            p="8"
          >
            <Heading as="h2" color={landingPageProcess.colorBase}>
              {landingPageProcess.titleInstitutional}
            </Heading>

            <Text
              dangerouslySetInnerHTML={{
                __html: landingPageProcess.descriptionInstitutional,
              }}
            ></Text>

            <Button
              as={ChakraLink}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="2"
              px="3"
              fontWeight="bold"
              bg="transparent"
              isDisabled={landingPageProcess.urlInstitutional === ''}
              color={landingPageProcess.colorBase}
              href={landingPageProcess.urlInstitutional}
              target="_blank"
              _hover={{
                filter: 'brightness(0.9)',
              }}
            >
              Saiba Mais
              <ArrowRight size={22} />
            </Button>
          </Flex>

          <Flex
            w="100%"
            maxW={600}
            justifyContent="space-between"
            flexDirection="column"
            gap="4"
            border={`2px solid ${landingPageProcess.colorBase}`}
            borderRadius={12}
            p="8"
          >
            <Heading as="h2" color={landingPageProcess.colorBase}>
              {landingPageProcess.titleProcess}
            </Heading>

            <Text
              dangerouslySetInnerHTML={{
                __html: landingPageProcess.descriptionProcess,
              }}
            ></Text>

            <Button
              as={ChakraLink}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="2"
              px="3"
              fontWeight="bold"
              bg="transparent"
              isDisabled={landingPageProcess.urlProcess === ''}
              color={landingPageProcess.colorBase}
              href={landingPageProcess.urlProcess}
              target="_blank"
              _hover={{
                filter: 'brightness(0.9)',
              }}
            >
              Saiba Mais
              <ArrowRight size={24} />
            </Button>
          </Flex>
        </Flex>

        {infoRegistration &&
          dateIsSameOrAfter(infoRegistration.dateStart) &&
          dateIsSameOrBefore(infoRegistration.dateEnd) && (
            <FormContactUs landingPageProcess={landingPageProcess} />
          )}

        <Flex
          maxW={1280}
          w="100%"
          justify="space-between"
          p="4"
          my={['8', '12']}
        >
          <Tabs variant="enclosed" w="100%">
            <TabList>
              <Tab
                _selected={{
                  color: 'white',
                  bg: landingPageProcess.colorBase,
                }}
              >
                Cronograma
              </Tab>
              <Tab
                _selected={{
                  color: 'white',
                  bg: landingPageProcess.colorBase,
                }}
              >
                Informações gerais
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <List spacing={3}>
                  {timelines.map((timeline) => (
                    <ListItem key={timeline.id}>
                      {dayjs(timeline.dateStart).isSame(timeline.dateEnd)
                        ? `${timeline.description} - ${dayjs(
                            timeline.dateStart,
                          ).format('DD/MM/YYYY')}`
                        : `${timeline.description} - ${dayjs(
                            timeline.dateStart,
                          ).format('DD/MM/YYYY')} até ${dayjs(
                            timeline.dateEnd,
                          ).format('DD/MM/YYYY')}`}
                      {dateIsSameOrAfter(timeline.dateStart) &&
                      dateIsSameOrBefore(timeline.dateEnd)
                        ? ' - Em andamento'
                        : dayjs(timeline.dateEnd).isBefore(new Date())
                        ? ' - Finalizado'
                        : ''}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List spacing={3}>
                  {documents.map((document) => (
                    <ListItem key={document.id}>
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'image' && (
                        <ListIcon
                          as={FileImage}
                          color={landingPageProcess.colorBase}
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'document' && (
                        <ListIcon
                          as={FileText}
                          color={landingPageProcess.colorBase}
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'pdf' && (
                        <ListIcon
                          as={FilePdf}
                          color={landingPageProcess.colorBase}
                        />
                      )}

                      {document.url ? (
                        <ChakraLink
                          h="12"
                          px="3"
                          fontWeight="bold"
                          color={landingPageProcess.colorBase}
                          href={document.url.split('^')[0]}
                          target="_blank"
                          _hover={{
                            filter: 'brightness(0.9)',
                          }}
                        >
                          {document.description}
                        </ChakraLink>
                      ) : (
                        document.description
                      )}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </>
  )
}
