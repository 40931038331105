/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Flex,
  Image,
  Link,
  Heading,
  Text,
  Skeleton,
  SkeletonText,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  ListItem,
  ListIcon,
  Icon,
  Button,
} from '@chakra-ui/react'
import {
  FileImage,
  FilePdf,
  FileText,
  WhatsappLogo,
} from '@phosphor-icons/react'

import api from '../services/api'
import { useLandingPageProcess } from '../hook/useLandingPageProcess'
import dateIsSameOrBefore from '../utils/dateIsSameOrBefore'
import dateIsSameOrAfter from '../utils/dateIsSameOrAfter'
import getMimeTypeFromExtension from '../utils/getMimeTypeFromExtension'

import { WhatsAppButton } from '../components/WhatsAppButton'

interface ResponseTimeline {
  id: number
  landingPageId: string
  description: string
  dateStart: string
  dateEnd: string
  subscriptionOpen: string
  url: string
}

interface ResponseDocument {
  id: number
  landingPageId: string
  description: string
  url: string
}

export function Success() {
  const [timelines, setTimelines] = useState<ResponseTimeline[]>([])
  const [documents, setDocuments] = useState<ResponseDocument[]>([])

  const { landingPageProcess } = useLandingPageProcess()

  const toast = useToast()

  async function fetchListTimeline() {
    try {
      const { data } = await api.get<ResponseTimeline[]>(
        `/landing-page-process/timeline/${landingPageProcess.id}`,
      )

      setTimelines(data)
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar lista a timeline',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  async function fetchListDocuments() {
    try {
      const { data } = await api.get<ResponseDocument[]>(
        `/landing-page-process/documents/all/${landingPageProcess.id}`,
      )

      setDocuments(data)
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar lista os documentos',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    fetchListTimeline()
    fetchListDocuments()
  }, [])

  if (!landingPageProcess.id) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          h={[280, 280, 120]}
          bgColor="#FFF"
        >
          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            align="center"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            px="6"
          >
            <Skeleton height="80px" width={200} />

            <Flex gap="4" flexDirection={['column', 'column', 'row']}>
              <Skeleton height="55px" width={200} />

              <Skeleton height="55px" width={200} />
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" w="100%" align="center">
          <Skeleton height="400px" width="100%" />

          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            p="4"
            my={['8', '12']}
          >
            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>

            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex
        as="header"
        w="100%"
        justify="center"
        align="center"
        position="fixed"
        zIndex="1000"
        h={[280, 280, 100]}
        bgColor={landingPageProcess.colorHeader}
      >
        <Flex
          maxW={1280}
          w="100%"
          justify="space-between"
          align="center"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          px="6"
        >
          <Image
            src={landingPageProcess.urlLogo.split('^')[0]}
            alt="Logo"
            maxW={180}
            w="100%"
          />
        </Flex>
      </Flex>

      <Flex
        as="main"
        flexDirection="column"
        w="100%"
        pt={[280, 280, 100]}
        align="center"
      >
        {landingPageProcess.whatsappNumber && (
          <WhatsAppButton
            href={`http://wa.me/${landingPageProcess.whatsappNumber}/?text=Ola`}
          />
        )}

        <Flex w="100%" justify="center">
          <Image
            src={landingPageProcess.urlBanner.split('^')[0]}
            alt=""
            maxW={1920}
            w="100%"
          />
        </Flex>

        <Flex
          w="100%"
          maxW={1280}
          flexDirection="column"
          align="center"
          gap="8"
          p="4"
          my={['8', '12']}
        >
          <Heading
            as="h2"
            textAlign="center"
            color={landingPageProcess.colorBase}
          >
            {landingPageProcess.titleThanks}
          </Heading>

          <Text
            maxW={900}
            textAlign="center"
            fontSize="lg"
            dangerouslySetInnerHTML={{
              __html: landingPageProcess.descriptionThanks,
            }}
          ></Text>

          {landingPageProcess.whatsappNumber && (
            <>
              <Text
                maxW={900}
                textAlign="center"
                mt="8"
                dangerouslySetInnerHTML={{
                  __html:
                    landingPageProcess.titleThanksWhatsapp === ''
                      ? 'Fale conosco pelo nosso WhastApp!'
                      : landingPageProcess.titleThanksWhatsapp,
                }}
              ></Text>

              <Button
                as={Link}
                href={`http://wa.me/${landingPageProcess.whatsappNumber}/?text=Ola`}
                target="_blank"
                rel="noopener"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="2"
                size="lg"
                color="white"
                bg="#34af23"
                border="2px solid transparent"
                _hover={{
                  textDecoration: 'none',
                  borderColor: '#34af23',
                  bg: 'transparent',
                  color: '#34af23',
                }}
              >
                <Icon as={WhatsappLogo} fontSize="30" />
                Clique aqui
              </Button>
            </>
          )}
        </Flex>

        <Flex
          maxW={1280}
          w="100%"
          justify="space-between"
          p="4"
          my={['8', '12']}
        >
          <Tabs variant="enclosed" w="100%">
            <TabList>
              <Tab
                _selected={{
                  color: 'white',
                  bg: landingPageProcess.colorBase,
                }}
              >
                Cronograma
              </Tab>
              <Tab
                _selected={{
                  color: 'white',
                  bg: landingPageProcess.colorBase,
                }}
              >
                Informações gerais
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <List spacing={3}>
                  {timelines.map((timeline) => (
                    <ListItem key={timeline.id}>
                      {dayjs(timeline.dateStart).isSame(timeline.dateEnd)
                        ? `${timeline.description} - ${dayjs(
                            timeline.dateStart,
                          ).format('DD/MM/YYYY')}`
                        : `${timeline.description} - ${dayjs(
                            timeline.dateStart,
                          ).format('DD/MM/YYYY')} até ${dayjs(
                            timeline.dateEnd,
                          ).format('DD/MM/YYYY')}`}
                      {dateIsSameOrAfter(timeline.dateStart) &&
                      dateIsSameOrBefore(timeline.dateEnd)
                        ? ' - Em andamento'
                        : dayjs(timeline.dateEnd).isBefore(new Date())
                        ? ' - Finalizado'
                        : ''}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List spacing={3}>
                  {documents.map((document) => (
                    <ListItem key={document.id}>
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'image' && (
                        <ListIcon
                          as={FileImage}
                          color={landingPageProcess.colorBase}
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'document' && (
                        <ListIcon
                          as={FileText}
                          color={landingPageProcess.colorBase}
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'pdf' && (
                        <ListIcon
                          as={FilePdf}
                          color={landingPageProcess.colorBase}
                        />
                      )}

                      {document.url ? (
                        <Link
                          h="12"
                          px="3"
                          fontWeight="bold"
                          color={landingPageProcess.colorBase}
                          href={document.url.split('^')[0]}
                          target="_blank"
                          _hover={{
                            filter: 'brightness(0.9)',
                          }}
                        >
                          {document.description}
                        </Link>
                      ) : (
                        document.description
                      )}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </>
  )
}
