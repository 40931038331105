import {
  Checkbox as ChakraCheckbox,
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormErrorMessage,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface CheckboxProps extends ChakraCheckboxProps {
  name: string
  label?: string
  colorLabel?: string
  error?: FieldError
}

const CheckboxBase: ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxProps
> = (
  { name, label, error = undefined, colorLabel = '#002147', ...rest },
  ref,
) => {
  return (
    <FormControl w="auto" isInvalid={!!error}>
      <ChakraCheckbox
        id={name}
        name={name}
        size="md"
        borderRadius="12"
        color={colorLabel}
        borderColor={colorLabel}
        _hover={{
          borderColor: colorLabel,
        }}
        _focus={{
          borderColor: colorLabel,
        }}
        ref={ref}
        {...rest}
      >
        {label}
      </ChakraCheckbox>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Checkbox = forwardRef(CheckboxBase)
