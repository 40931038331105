import { Route, Routes as RoutesDOM } from 'react-router-dom'

import RouteLandingPageProcess from './RouteLandingPageProcess'

import Error404 from '../pages/Error404'
import { Home } from '../pages/Home'
import { Success } from '../pages/Success'

export const Routes = () => {
  return (
    <RoutesDOM>
      <Route path="*" element={<Error404 />} />
      <Route path="/:processAlias" element={<RouteLandingPageProcess />}>
        <Route path="/:processAlias" element={<Home />} />
      </Route>
      <Route
        path="/:processAlias/agradecimento"
        element={<RouteLandingPageProcess />}
      >
        <Route path="/:processAlias/agradecimento" element={<Success />} />
      </Route>
    </RoutesDOM>
  )
}
