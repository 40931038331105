import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'

import AppProvider from './context'
import { Routes } from './routes'

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ChakraProvider>
    </BrowserRouter>
  )
}

export default App
